/*
 * Copyright 2023-2024 NXP
 */

import COLORS from "@/src/lib/colors";
import { blue, grey } from "@mui/material/colors";

const styles = {
  undecorated: {
    color: "inherit",
    textDecoration: "inherit",
  },
  disabledLink: {
    width: "fit-content",
    "&[disabled]": {
      ariaDisabled: true,
      tabIndex: -1,
      color: "inherit",
      opacity: 0.5,
      pointerEvents: "none",
    },
  },
  hoverColor: {
    "&:hover": {
      color: blue[900],
    },
  },
  disabledLinks: { color: COLORS.grey[400] },
  activeGreenLink: {
    color: COLORS.teal[900],
    "&:hover": {
      color: COLORS.teal[400],
    },
  },
  activeBlackLink: {
    color: grey[900],
    "&:hover": {
      color: grey[700],
    },
  },
  activeLightLink: {
    color: COLORS.grey[100],
    "&:hover": {
      color: COLORS.grey[400],
    },
  },
};

export default styles;
