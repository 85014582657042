/*
 * Copyright 2024 NXP
 */
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import gStyles from "@/src/components/styles";
import ReportIcon from "@mui/icons-material/Report";
import COLORS from "@/src/lib/colors";
import LinkStyled from "@/src/components/UI/LinkStyled/LinkStyled";

export default function ErrorUI() {

  return (
    <html lang="en">
      {/* TODO: KEX-10848 */}
      <body>
        <Stack
          sx={gStyles.errorContainer}
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <ReportIcon sx={gStyles.pageErrorIcon} fontSize="large" />
            <Typography variant="h4" fontWeight="bold" color={COLORS.teal[900]}>
              Oops, service is currently unavailable...
            </Typography>
          </Stack>
          <Stack direction="column" alignItems="center" spacing={1}>
            <Typography sx={gStyles.pageErrorText}>
              The problem has been already logged by our monitoring service and
              we will try to resolve it within soonest possible time.
            </Typography>
            <Typography>
              Please try to{" "}
              <LinkStyled href={"/"} underline={"hover"}>
                go to the homepage
              </LinkStyled>{" "}
              in a moment or{" "}
              <LinkStyled
                underline={"hover"}
                href="https://www.nxp.com/support/support:SUPPORTHOME"
                target="_blank"
              >
                contact the NXP Semiconductors Customer Support Team
              </LinkStyled>{" "}
              to get more information about incident.
            </Typography>
            <Typography sx={gStyles.pageErrorText}>
              Please accept our apologies for any inconvenience.
            </Typography>
          </Stack>
        </Stack>
      </body>
    </html>
  );
}
