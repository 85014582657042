/*
 * Copyright 2024 NXP
 */

import React, { forwardRef } from "react";
import NextLinkWithoutLocale, { LinkProps as NextLinkProps } from "next/link";

import { Link } from "@mui/material";

interface NextLinkPropsWithoutLocale extends NextLinkProps {
  children?: React.ReactNode;
}

const LinkWithoutLocale = forwardRef<
  HTMLAnchorElement,
  NextLinkPropsWithoutLocale
>(({ children, prefetch = false, ...rest }, ref) => {
  return (
    <Link
      ref={ref}
      prefetch={prefetch}
      component={NextLinkWithoutLocale}
      {...rest}
    >
      {children}
    </Link>
  );
});

export default LinkWithoutLocale;
