/*
 * Copyright 2024 NXP
 */
"use client";

import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";
import ErrorUI from "@/src/app/[locale]/errorUI";

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  console.log({ error });

  return <ErrorUI />;
}
