/*
 * Copyright 2024 NXP
 */

import { LinkProps as NextLinkProps } from "next/link";
import Link, { LinkProps as MuiLinkProps } from "@mui/material/Link";
import styles from "./styles";

import React from "react";
import LinkWithLocale from "@/src/components/UI/LinkWithLocale/LinkWithLocale";
import LinkWithoutLocale from "@/src/components/UI/LinkWithoutLocale/LinkWithoutLocale";

export type LinkStyledProps = {
  disabled?: boolean;
  hoverColor?: boolean;
  href?: NextLinkProps["href"];
  ignoreLocale?: boolean;
  prefetch?: boolean;
} & Omit<MuiLinkProps, "href">;

const LinkStyled = (props: LinkStyledProps) => {
  const {
    href = "#",
    onClick,
    sx,
    hoverColor = false,
    children,
    ignoreLocale,
    prefetch = false,
    ...rest
  } = props;
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick) {
      e.preventDefault();
      onClick(e);
    }
  };

  return (
    <Link
      sx={{
        ...sx,
        ...styles.disabledLink,
        ...(hoverColor && styles.hoverColor),
      }}
      href={href}
      onClick={handleClick}
      prefetch={prefetch}
      component={ignoreLocale ? LinkWithoutLocale : LinkWithLocale}
      {...rest}
    >
      {children}
    </Link>
  );
};

export default LinkStyled;
