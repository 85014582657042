/*
 * Copyright 2024 NXP
 */

import React, { forwardRef } from "react";
import { LinkProps as NextLinkProps } from "next/link";
import { Link as NextLink } from "@/src/navigation";
import { Link } from "@mui/material";

interface NextLinkPropsWithLocale extends NextLinkProps {
  locale?: "en" | "zh";
  children?: React.ReactNode;
  target?: string;
}

const LinkWithLocale = forwardRef<HTMLAnchorElement, NextLinkPropsWithLocale>(
  ({ children, prefetch = false, ...rest }, ref) => {
    return (
      <Link ref={ref} prefetch={prefetch} component={NextLink} {...rest}>
        {children}
      </Link>
    );
  },
);

export default LinkWithLocale;
